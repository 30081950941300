// -------------------------------------
// Navlink Active
// -------------------------------------
(function($) {
	$(function() {

		$(".nav-link").on("click", function() {
			if ($(this).hasClass("dropdown-toggle")) {
				// Do nothing
			} else {
				$(".nav-link").removeClass("active").parent(".nav-item").removeClass("active");
				$(this).addClass("active").parent(".nav-item").addClass("active");
			}
		});

	});
})(jQuery);