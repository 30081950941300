// -------------------------------------
// UI Mask
// -------------------------------------
(function() {
	// Add cssClassChanged event
	var originalAddClassMethod = jQuery.fn.addClass;

	jQuery.fn.addClass = function() {
		// Execute the original method.
		var result = originalAddClassMethod.apply(this, arguments);

		// trigger a custom event
		jQuery(this).trigger("cssClassChanged");

		// return the original result
		return result;
	}
})();

(function($) {
	$(function() {

		var $drawer = $(".drawer");
		var $mask = $(".ui-mask");
		var $toggler = $(".navbar-toggler");

		var speed = 250;

		// Close all drawers and dropdowns when clicking on black overlay
		$mask.on("click", function() {
			$drawer.addClass("transitioning").removeClass("show").addClass("hide").css("transform", "");
			$mask.removeClass("show");
			$toggler.removeClass("is-active");
			$("body").removeClass("no-scrolling");
			setTimeout(function() {
				$drawer.removeClass("transitioning");
			}, speed);
		});

		$drawer.on("cssClassChanged", function() {
			// console.log("Drawer css class changed");
			if ($(this).hasClass("show")) {
				// Show ui mask and stop scrolling
				$mask.addClass("show");
				$("body").addClass("no-scrolling");
			} else {
				// Close ui mask and resume scrolling
				$mask.removeClass("show");
				$("body").removeClass("no-scrolling");
			}
		});

		// Disable scrolling
		$("body.no-scrolling").on("touchstart touchmove", function() {
			t.preventDefault();
			e.stopPropagation();
		});

	});
})(jQuery);