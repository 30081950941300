// -------------------------------------
// Bootstrap Dropdowns
// -------------------------------------
(function($) {
	$(function() {

		// Slide down animation on open
		$(".dropdown").on("show.bs.dropdown", function(e) {
			$(this).find(".dropdown-menu").first().stop(true, true).slideDown(250);
		});

		// Slide up animation on close
		$(".dropdown").on("hide.bs.dropdown", function(e) {
			$(this).find(".dropdown-menu").first().stop(true, true).slideUp(250);
		});

	});
})(jQuery);