// -------------------------------------
// Adding/added to cart
// -------------------------------------
(function($) {
	$(function() {

		$(".add_to_cart_button.product_type_simple, .single_add_to_cart_button:not(.disabled)").on("click", function() {
			$(this).addClass("adding disabled");
		});

		$(document.body).on("added_to_cart", function() {
			$(".add_to_cart_button, .single_add_to_cart_button").removeClass("adding disabled");
		});

	});
})(jQuery);